var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expande-horizontal fonte bg-top",staticStyle:{"min-height":"100vh"}},[(_vm.charging)?_c('div',[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal centraliza column"},[_c('h3',[_vm._v("Buscando...")])])])],1):_c('div',{staticClass:"expande-horizontal centraliza column",staticStyle:{"min-height":"90vh"}},[(_vm.compra.compra.loja)?_c('img',{staticStyle:{"width":"80px","border-radius":"50%"},attrs:{"src":_vm.compra.compra.loja.profileImage,"alt":""}}):_c('img',{staticStyle:{"width":"50px","border-radius":"6px"},attrs:{"src":"img/logo_mini.png","alt":""}}),(_vm.compra.compra.loja)?_c('h3',{staticClass:"fonte blue--text px-3 mt-3 mb-3"},[_vm._v(" "+_vm._s(_vm.compra.compra.loja.nome)+" ")]):_c('h3',{staticClass:"fonte dark--text px-3 mt-3 mb-3"},[_vm._v(" Veja o andamento do seu pedido ")]),(_vm.compra.status_do_pedido !== 'recusada')?_c('v-list',{staticClass:"fonte ma-0 pa-0",attrs:{"color":"transparent","dense":"","nav":""}},[_c('v-divider',{staticClass:"mb-2"}),_vm._l((_vm.status_type[_vm.compra.compra.tipo]),function(status,index){return [_c('v-list-item',{key:`id-status-${status}-${index}`,class:{
            'item-current-status': status.value === _vm.compra.status_do_pedido
          }},[(_vm.statusChecked(status.value).status)?_c('v-avatar',{staticClass:"animate__heartBeat mr-3",attrs:{"size":"36","color":status.color}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(status.icon)+" ")])],1):_c('v-avatar',{staticClass:"animate__heartBeat mr-3",attrs:{"size":"36","color":"grey"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-lock-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
                'font-weight-bold': status.value === _vm.compra.status_do_pedido
              }},[_vm._v(" "+_vm._s(status.text)+" ")]),(_vm.statusChecked(status.value).status)?_c('v-list-item-subtitle',{class:{
                'dark--text': status.value === _vm.compra.status_do_pedido
              }},[_vm._v(" "+_vm._s(_vm.$moment(_vm.statusChecked(status.value).data).format( "DD/MM/YYYY [às] HH:mm" ))+" ")]):_vm._e()],1),_c('v-list-item-action',[(
                _vm.statusChecked(status.value).status &&
                  status.value !== _vm.compra.status_do_pedido
              )?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")])],1):_vm._e(),(
                _vm.statusChecked(status.value).status &&
                  status.value === _vm.compra.status_do_pedido &&
                  status.value !== 'concluído'
              )?_c('v-btn',{attrs:{"x-small":"","color":_vm.$theme.primary,"dark":"","rounded":"","text":""}},[_c('div',{staticStyle:{"width":"50px"}},[_c('v-progress-linear',{staticClass:"ml-1",attrs:{"indeterminate":"","size":"12","color_as":status.color,"color":"#333"}})],1)]):_vm._e()],1)],1)]})],2):_c('div',{staticClass:"fonte expande-horizontal column centraliza",staticStyle:{"max-width":"300px","border-top":"1px dashed #999"}},[_c('span',{staticClass:"text-center grey--text",staticStyle:{"max-width":"300px"}},[_vm._v("Infelizmente a loja não aceitou o pedido, veja o motivo: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.compra.compra.motivo_recusa))])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }